/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
import { KyInstance } from "ky/distribution/types/ky";
import ky, { Options } from "ky";
import { makeBinded } from "@/proxies";

type WithData<T> = {
  data: T;
};

export class HttpService {
  protected http: KyInstance;

  constructor(
    config: Options = {
      timeout: 30000,
      headers: {
        "Access-Control-Allow-Origin": "*",
      },
    }
  ) {
    this.http = ky.create(config);
  }

  aGet<T>(url: string, options?: Options) {
    return this.http.get(url, options).json<WithData<T>>();
  }

  aPost<T>(url: string, options?: Options) {
    return this.http.post(url, options).json<WithData<T>>();
  }

  aDelete<T>(url: string, options?: Options) {
    return this.http.delete(url, options).json<WithData<T>>();
  }

  aDeleteText(url: string, options?: Options) {
    return this.http.delete(url, options).text();
  }

  aPatch<T>(url: string, options?: Options) {
    return this.http.patch(url, options).json<WithData<T>>();
  }

  aPatchText<T>(url: string, options?: Options) {
    return this.http.patch(url, options).text();
  }

  aPut<T>(url: string, options?: Options) {
    return this.http.put(url, options).json<WithData<T>>();
  }

  aPostText(url: string, options?: Options) {
    return this.http.post(url, options).text();
  }
}

export const httpService = makeBinded(HttpService);
