import { interfaccia_amministro, interfaccia_archivioplus, interfaccia_notificoonline, Profile } from "@amministro-io-packages/auth-interfaces";
import { compare } from "@/lib/utils";
import { capitalize } from "@/lib/strings";
import { makeBinded } from "@/proxies";
import {
  appInterfaceAmministroValues,
  appInterfaceArchivioplusValues,
  appInterfaceNotificoonlineValues,
} from "@amministro-io-packages/administration-interfaces";

export type AppInterface = Profile["interfaccia_amministroio"] | Profile["interfaccia_archivioplus"] | Profile["interfaccia_notificoonline"] | "ADMINISTRATION";

export type AppListItem = {
  label: string | null | undefined;
  link: string | null | undefined;
  product: "amministroio" | "archivio" | "notifico" | "admin";
  value: boolean;
  app: AppInterface;
};

export type AppList = Array<AppListItem>;

export class BindingService {
  /**
   * Un profilo di tipo SYS non dispone di interfacce; le applicazioni a cui ha accesso
   * sono hard-coded lato client (amministro.io, admin.amministro.io)
   */
  private _appsFromSysProfile(): AppList {
    return [
      {
        label: this.nameFromApp(process.env.VUE_APP_ADMINISTRATION_ID as AppInterface),
        link: this.urlFromApp(process.env.VUE_APP_ADMINISTRATION_ID as AppInterface),
        product: "admin",
        value: true,
        app: process.env.VUE_APP_ADMINISTRATION_ID as AppInterface,
      },
    ];
  }

  /**
   * Un profilo di tipo SPL non dispone di interfacce; le applicazioni a cui ha accesso
   * sono hard-coded lato client (amministro.io, archivio.plus)
   */
  private _appsFromSplProfile(): AppList {
    return [
      {
        // AMMINISTROIO
        label: this.nameFromApp(process.env.VUE_APP_AMMINISTROIO_ID as AppInterface),
        link: this.urlFromApp(process.env.VUE_APP_AMMINISTROIO_ID as AppInterface),
        product: "amministroio",
        value: true,
        app: process.env.VUE_APP_AMMINISTROIO_ID as AppInterface,
      },
      {
        // ARCHIVIOPLUS
        label: this.nameFromApp(process.env.VUE_APP_ARCHIVIOPLUS_ID as AppInterface),
        link: this.urlFromApp(process.env.VUE_APP_ARCHIVIOPLUS_ID as AppInterface),
        product: "archivio",
        value: true,
        app: process.env.VUE_APP_ARCHIVIOPLUS_ID as AppInterface,
      },
    ];
  }

  /**
   * Dato un'url prova ad associarlo ad un'app.
   * @param href - l'url di riferimento
   */
  appFromUrl(href: string | null): AppInterface {
    if (href === null) return href;

    try {
      const { origin } = new URL(href);

      const envkey = Object.keys(process.env).find((key) => process.env[key] === origin) || "";

      return (/VUE_APP_(.*?)_URL/s.exec(envkey)?.[1] || null) as AppInterface;
    } catch (e) {
      // TODO: notify error
      return null;
    }
  }

  /**
   * Data un'app ritorna l'url ad essa associata (se l'app esiste)
   * @param app - l'app da cui estrarre l'url
   */
  urlFromApp(app: AppInterface): string | null {
    if (process.env[`VUE_APP_${app}_ID`]) {
      return process.env[`VUE_APP_${app}_URL`] as string;
    } else {
      return null;
    }
  }

  /**
   * Data un'app ritorna l'icona ad essa associata (se l'app esiste)
   * @param app - l'app da cui estrarre l'icon
   */
  iconFromApp(app: AppInterface): string | null {
    if (process.env[`VUE_APP_${app}_ID`]) {
      return process.env[`VUE_APP_${app}_LOGO_MINI`] as string;
    } else {
      return null;
    }
  }

  /**
   * Data un'app ritorna il nome ad essa associata
   * @param app
   */
  nameFromApp(app: AppInterface): string | null {
    if (process.env[`VUE_APP_${app}_ID`]) {
      return process.env[`VUE_APP_${app}_DISPLAY_NAME`] as string;
    } else {
      return null;
    }
  }

  /**
   * Dato un profilo, ritorna la lista di tutte le app alle quali potrebbe accedere.
   * @param profile - il profilo dal quale estrarre le app
   */
  appsFromProfile(profile: Profile): AppList {
    const { role } = profile;

    const clean = (apps: AppList) => {
      return (
        apps
          .filter((v) => v.value && v.label)
          // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
          .map((v) => ({ ...v, label: capitalize(v.label!) }))
          .sort(compare<AppListItem>("asc")("product"))
      );
    };

    if (profile.company_type === "org_contacts" || profile.company_type === "sys_organizations") {
      const { moduleAmministroio, moduleNotificoOnline, moduleArchivioPlus } = profile.moduli;

      const { interfaccia_amministroio, interfaccia_notificoonline, interfaccia_archivioplus } = profile;

      return clean([
        {
          app: interfaccia_amministroio,
          label: this.nameFromApp(interfaccia_amministroio),
          product: "amministroio",
          link: this.urlFromApp(interfaccia_amministroio),
          value: moduleAmministroio,
        },
        {
          app: interfaccia_notificoonline,
          label: this.nameFromApp(interfaccia_notificoonline),
          product: "notifico",
          link: this.urlFromApp(interfaccia_notificoonline),
          value: moduleNotificoOnline,
        },
        {
          app: interfaccia_archivioplus,
          label: this.nameFromApp(interfaccia_archivioplus),
          product: "archivio",
          link: this.urlFromApp(interfaccia_archivioplus),
          value: moduleArchivioPlus,
        },
        {
          app: process.env.VUE_APP_ADMINISTRATION_ID as AppInterface,
          label: this.nameFromApp(process.env.VUE_APP_ADMINISTRATION_ID as AppInterface),
          product: "admin",
          link: this.urlFromApp(process.env.VUE_APP_ADMINISTRATION_ID as AppInterface),
          value: role.startsWith("sys"),
        },
      ]);
    } else {
      return clean(profile.role.startsWith("sys") ? this._appsFromSysProfile() : this._appsFromSplProfile());
    }
  }

  isAmministroInterface(value: string): value is interfaccia_amministro {
    return (appInterfaceAmministroValues as string[]).includes(value);
  }

  isArchivioInterface(value: string): value is interfaccia_archivioplus {
    return (appInterfaceArchivioplusValues as string[]).includes(value);
  }

  isNotificoInterface(value: string): value is interfaccia_notificoonline {
    return (appInterfaceNotificoonlineValues as string[]).includes(value);
  }

  appFromAppInterface(appInterface: AppInterface) {
    if (!appInterface) return null;

    if (this.isAmministroInterface(appInterface)) return "amministroio";

    if (this.isArchivioInterface(appInterface)) return "archivio";

    if (this.isNotificoInterface(appInterface)) return "notifico";

    if (appInterface === "ADMINISTRATION") return "admin";

    return null;
  }
}

export const bindingService = makeBinded(BindingService);
