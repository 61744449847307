class BuildingsEndpointsVO {
  private _baseURL({ origin = process.env.VUE_APP_AMMINISTRO_API_GATEWAY, v = "v2" }: { origin?: string; v?: string }) {
    return `${origin}/api/${v}`;
  }

  findOneFromNin({ v, nin, getIds }: { v?: string; nin: string; getIds: boolean }) {
    if (getIds) return `${this._baseURL({ v })}/public/buildings/from-nin/${nin}?getData=true`;
    return `${this._baseURL({ v })}/public/buildings/from-nin/${nin}`;
  }

  findOne({ v, id }: { v?: string; id: string }) {
    return `${this._baseURL({ v })}/buildings/${id}`;
  }

  findMany({ v }: { v?: string }) {
    return `${this._baseURL({ v })}/buildings/search`;
  }

  findManyWithRas({ v }: { v?: string }) {
    return `${this._baseURL({ v })}/buildings/search?expand=ras-true`;
  }

  findManyWitoutRas({ v }: { v?: string }) {
    return `${this._baseURL({ v })}/buildings/search?expand=ras-false`;
  }

  enableBuildingless({ v }: { v?: string }) {
    return `${this._baseURL({ v })}/buildings/enable/buildingless`;
  }

  disableBuildingless({ v }: { v?: string }) {
    return `${this._baseURL({ v })}/buildings/disable/buildingless`;
  }

  getBuildingUnits({ v }: { v?: string }) {
    return `${this._baseURL({ v })}/units/search/count`;
  }
}

const buildingsEndpointsVO = new BuildingsEndpointsVO();

export { buildingsEndpointsVO, BuildingsEndpointsVO };
